$wot-tournament-widget-table-image-width: 640px;
$wot-tournament-widget-table-image-height: 712px;

#wot-tournament-widget-table-offcanvas.offcanvas-end,
#wot-tournament-widget-table-offcanvas.offcanvas-start,
#wot-tournament-widget-table-offcanvas.offcanvas-top,
#wot-tournament-widget-table-offcanvas.offcanvas-bottom {
  font-family: 'VelaSans-Medium';
  width: $wot-tournament-widget-table-image-width !important;
  height: $wot-tournament-widget-table-image-height !important;
  border: none !important;
}

#wot-tournament-widget-table-offcanvas {
  background-color: transparent !important;
}

#wot-tournament-widget-table-offcanvas .offcanvas-body {
  overflow: hidden !important;
  width: $wot-tournament-widget-table-image-width !important;
  height: $wot-tournament-widget-table-image-height !important;
  padding: 0;
}

.wot-tournament-widget-table-container {
  position: relative;
  width: $wot-tournament-widget-table-image-width;
  height: $wot-tournament-widget-table-image-height;
  color: white;
}

.wot-tournament-widget-table-table-name {
  position: absolute;
  margin: 0;
  top: 118px;
  left: 184px;
  width: 130px;
  height: 24px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
}

.wot-tournament-widget-table-total-players {
  position: absolute;
  margin: 0;
  top: 118px;
  left: 327px;
  width: 183px;
  height: 24px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: rgb(193, 28, 28);
  font-size: 16px;
}

// background images
@for $i from 0 through 8 {
  .wot-tournament-widget-table-place-#{$i} {
    background-image: url('./images/table-place-#{$i}.png');
  }
}

.wot-tournament-widget-table-place-center {
  background-image: url('./images/table-place-center.png');
}

.wot-tournament-widget-table-place-last {
  background-image: url('./images/table-place-last.png');
}

.wot-tournament-widget-table-place-prelast {
  background-image: url('./images/table-place-prelast.png');
}

// top scores
$wot-tournament-widget-table-player-top-scores-top: 247px;
.wot-tournament-widget-table-player-scores-first-place {
  position: absolute;
  margin: 0;
  top: $wot-tournament-widget-table-player-top-scores-top;
  left: 155px;
  width: 80px;
  height: 24px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
}

.wot-tournament-widget-table-player-scores-second-place {
  position: absolute;
  margin: 0;
  top: $wot-tournament-widget-table-player-top-scores-top;
  left: 284px;
  width: 80px;
  height: 24px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
}

.wot-tournament-widget-table-player-scores-third-place {
  position: absolute;
  margin: 0;
  top: $wot-tournament-widget-table-player-top-scores-top;
  left: 412px;
  width: 80px;
  height: 24px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
}

// 0
$wot-tournament-widget-table-player-0-top: 200px;
$wot-tournament-widget-table-player-place-left: 134px;
$wot-tournament-widget-table-player-name-left: 160px;
$wot-tournament-widget-table-player-scores-left: 430px;

.wot-tournament-widget-table-player-place-0 {
  position: absolute;
  margin: 0;
  top: $wot-tournament-widget-table-player-0-top;
  left: $wot-tournament-widget-table-player-place-left;
  width: 16px;
  height: 40px;
  text-align: left;
  white-space: nowrap;
  overflow: visible;
  font-size: 30px;
}

.wot-tournament-widget-table-player-name-0 {
  position: absolute;
  margin: 0;
  top: $wot-tournament-widget-table-player-0-top;
  left: $wot-tournament-widget-table-player-name-left;
  width: 230px;
  height: 40px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 30px;
}

.wot-tournament-widget-table-player-scores-0 {
  position: absolute;
  margin: 0;
  top: $wot-tournament-widget-table-player-0-top;
  left: 391px;
  width: 100px;
  height: 40px;
  text-align: left;
  white-space: nowrap;
  overflow: visible;
  font-size: 26px;
}

// 1-8
.wot-tournament-widget-table-player-place {
  position: absolute;
  margin: 0;
  width: 24px;
  height: 24px;
  text-align: left;
  white-space: nowrap;
  overflow: visible;
  font-size: 16px;
}

.wot-tournament-widget-table-player-name {
  position: absolute;
  margin: 0;
  width: 260px;
  height: 24px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
}

.wot-tournament-widget-table-player-scores {
  position: absolute;
  margin: 0;
  width: 60px;
  height: 24px;
  text-align: left;
  white-space: nowrap;
  overflow: visible;
  font-size: 16px;
}

$wot-tournament-widget-table-player-top: 256px;
$wot-tournament-widget-table-player-top-shift: 38px;
@for $i from 1 through 9 {
  .wot-tournament-widget-table-player-place-#{$i} {
    @extend .wot-tournament-widget-table-player-place;
    top: $wot-tournament-widget-table-player-top +
      $i *
      $wot-tournament-widget-table-player-top-shift;
    left: $wot-tournament-widget-table-player-place-left;
  }

  .wot-tournament-widget-table-player-name-#{$i} {
    @extend .wot-tournament-widget-table-player-name;
    top: $wot-tournament-widget-table-player-top +
      $i *
      $wot-tournament-widget-table-player-top-shift;
    left: $wot-tournament-widget-table-player-name-left;
  }

  .wot-tournament-widget-table-player-scores-#{$i} {
    @extend .wot-tournament-widget-table-player-scores;
    top: $wot-tournament-widget-table-player-top +
      $i *
      $wot-tournament-widget-table-player-top-shift;
    left: $wot-tournament-widget-table-player-scores-left;
  }
}

// preprelast
$wot-tournament-widget-table-player-preprelast-top: 484px;
.wot-tournament-widget-table-player-place-preprelast {
  @extend .wot-tournament-widget-table-player-place;
  top: $wot-tournament-widget-table-player-preprelast-top;
  left: $wot-tournament-widget-table-player-place-left;
  width: 45px;
}

.wot-tournament-widget-table-player-name-preprelast {
  @extend .wot-tournament-widget-table-player-name;
  top: $wot-tournament-widget-table-player-preprelast-top;
  left: $wot-tournament-widget-table-player-name-left + 20;
  width: 240px;
}

.wot-tournament-widget-table-player-scores-preprelast {
  @extend .wot-tournament-widget-table-player-scores;
  top: $wot-tournament-widget-table-player-preprelast-top;
  left: $wot-tournament-widget-table-player-scores-left;
}

// prelast
.wot-tournament-widget-table-player-place-prelast {
  @extend .wot-tournament-widget-table-player-place;
  top: $wot-tournament-widget-table-player-preprelast-top +
    $wot-tournament-widget-table-player-top-shift;
  left: $wot-tournament-widget-table-player-place-left;
  width: 45px;
}

.wot-tournament-widget-table-player-name-prelast {
  @extend .wot-tournament-widget-table-player-name;
  top: $wot-tournament-widget-table-player-preprelast-top +
    $wot-tournament-widget-table-player-top-shift;
  left: $wot-tournament-widget-table-player-name-left + 20;
  width: 240px;
}

.wot-tournament-widget-table-player-scores-prelast {
  @extend .wot-tournament-widget-table-player-scores;
  top: $wot-tournament-widget-table-player-preprelast-top +
    $wot-tournament-widget-table-player-top-shift;
  left: $wot-tournament-widget-table-player-scores-left;
}

// last
.wot-tournament-widget-table-player-place-last {
  @extend .wot-tournament-widget-table-player-place;
  top: $wot-tournament-widget-table-player-preprelast-top +
    $wot-tournament-widget-table-player-top-shift * 2;
  left: $wot-tournament-widget-table-player-place-left;
  width: 45px;
}

.wot-tournament-widget-table-player-name-last {
  @extend .wot-tournament-widget-table-player-name;
  top: $wot-tournament-widget-table-player-preprelast-top +
    $wot-tournament-widget-table-player-top-shift * 2;
  left: $wot-tournament-widget-table-player-name-left + 20;
  width: 240px;
}

.wot-tournament-widget-table-player-scores-last {
  @extend .wot-tournament-widget-table-player-scores;
  top: $wot-tournament-widget-table-player-preprelast-top +
    $wot-tournament-widget-table-player-top-shift * 2;
  left: $wot-tournament-widget-table-player-scores-left;
}

// precenter
$wot-tournament-widget-table-player-precenter-top: 446px;
.wot-tournament-widget-table-player-place-precenter {
  @extend .wot-tournament-widget-table-player-place;
  top: $wot-tournament-widget-table-player-precenter-top;
  left: $wot-tournament-widget-table-player-place-left;
  width: 45px;
}

.wot-tournament-widget-table-player-name-precenter {
  @extend .wot-tournament-widget-table-player-name;
  top: $wot-tournament-widget-table-player-precenter-top;
  left: $wot-tournament-widget-table-player-name-left + 20;
  width: 240px;
}

.wot-tournament-widget-table-player-scores-precenter {
  @extend .wot-tournament-widget-table-player-scores;
  top: $wot-tournament-widget-table-player-precenter-top;
  left: $wot-tournament-widget-table-player-scores-left;
}

// center
.wot-tournament-widget-table-player-place-center {
  @extend .wot-tournament-widget-table-player-place;
  top: $wot-tournament-widget-table-player-precenter-top +
    $wot-tournament-widget-table-player-top-shift;
  left: $wot-tournament-widget-table-player-place-left;
  width: 45px;
}

.wot-tournament-widget-table-player-name-center {
  @extend .wot-tournament-widget-table-player-name;
  top: $wot-tournament-widget-table-player-precenter-top +
    $wot-tournament-widget-table-player-top-shift;
  left: $wot-tournament-widget-table-player-name-left + 20;
  width: 240px;
}

.wot-tournament-widget-table-player-scores-center {
  @extend .wot-tournament-widget-table-player-scores;
  top: $wot-tournament-widget-table-player-precenter-top +
    $wot-tournament-widget-table-player-top-shift;
  left: $wot-tournament-widget-table-player-scores-left;
}

// postcenter
.wot-tournament-widget-table-player-place-postcenter {
  @extend .wot-tournament-widget-table-player-place;
  top: $wot-tournament-widget-table-player-precenter-top +
    $wot-tournament-widget-table-player-top-shift * 2;
  left: $wot-tournament-widget-table-player-place-left;
  width: 45px;
}

.wot-tournament-widget-table-player-name-postcenter {
  @extend .wot-tournament-widget-table-player-name;
  top: $wot-tournament-widget-table-player-precenter-top +
    $wot-tournament-widget-table-player-top-shift * 2;
  left: $wot-tournament-widget-table-player-name-left + 20;
  width: 240px;
}

.wot-tournament-widget-table-player-scores-postcenter {
  @extend .wot-tournament-widget-table-player-scores;
  top: $wot-tournament-widget-table-player-precenter-top +
    $wot-tournament-widget-table-player-top-shift * 2;
  left: $wot-tournament-widget-table-player-scores-left;
}
