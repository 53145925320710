.filter-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 2rem;
}

@media all and (max-width: 500px) {
  .filter-container {
    flex-direction: column;
    justify-content: flex-start;
    padding-bottom: 1rem;
  }
}
