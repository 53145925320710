@import '../../../App.scss';

.header-container {
  margin-left: 3rem;
  margin-right: 3rem;
}

@media all and (max-width: 500px) {
  .header-container {
    margin-left: 0;
    margin-right: 0;
  }
}

.header-spinner-container {
  width: 2rem;
}

.header-menu-item {
  font-size: 1.5rem;
  margin-bottom: 0rem;
  color: #46473e;
}

.nav-item > .dropdown-menu {
  @extend .bg-metal-light;
}

@media (max-width: 900px) {
  .header-input-container {
    padding-top: 1rem;
  }
}

@media (min-width: 901px) {
  .header-input-container {
    margin-left: 1rem;
  }
}

.header-icon-container {
  width: 2rem;
  margin-right: 3rem;
}

.header-buttons-container {
  margin-left: 1rem;
}

@media screen and (max-width: 500px) {
  .header-buttons-container {
    margin-top: 0.5rem;
    margin-left: 0;
  }
}
