@font-face {
  font-family: 'VelaSans-ExtraBold';
  src: url('fonts/VelaSans-ExtraBold.otf');
}

@font-face {
  font-family: 'VelaSans-Medium';
  src: url('fonts/VelaSans-Medium.otf');
}

.min-vh-60 {
  min-height: 60vh;
}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}

.table-normal-header th {
  font-weight: normal;
}

table td b {
  padding-left: 0.5rem;
}

.success-background {
  background-color: #d4edda !important;
}

.warning-background {
  background-color: #fff3cd !important;
}

.bg-metal {
  background-color: #e7e7e5;
}

.bg-metal-light {
  background-color: #f0f0ee;
}

.bg-success {
  background-color: #d4edda !important;
}

@media (max-width: 900px) {
  .main-container {
    margin-left: 0rem;
    margin-right: 0rem;
  }
}

@media (min-width: 901px) {
  .main-container {
    margin-left: 3rem;
    margin-right: 3rem;
  }
}

.table-cell-icon {
  margin-top: -0.25rem;
}

.increase-font-size {
  font-size: 1.25rem;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1rem;
  margin: 0;
  font-weight: bold;
}

.label-no-wrap {
  white-space: nowrap;
}

.label-warning {
  background-color: lightcoral;
}

.label-success {
  background-color: lightgreen;
}

.filter-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 1rem;
}

@media all and (max-width: 500px) {
  .filter-container {
    flex-direction: column;
    justify-content: flex-start;
    padding-bottom: 1rem;
  }
}

.table-page-input {
  max-width: 4rem;
  text-align: center;
}

.table-bordered th {
  border-bottom: 1px solid;
}

.narrow-page {
  margin-left: 15%;
  margin-right: 15%;
}

@media all and (max-width: 500px) {
  .narrow-page {
    margin-left: 0;
    margin-right: 0;
  }
}

.react-datepicker-wrapper input {
  padding: 0.4rem;

  border-bottom-color: rgb(222, 226, 230);
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-image-outset: 0;
  border-image-repeat: stretch;
  border-image-slice: 100%;
  border-image-source: none;
  border-image-width: 1;
  border-left-color: rgb(222, 226, 230);
  border-left-style: solid;
  border-left-width: 1px;
  border-right-color: rgb(222, 226, 230);
  border-right-style: solid;
  border-right-width: 1px;
  border-top-color: rgb(222, 226, 230);
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border-top-style: solid;
  border-top-width: 1px;
}

.standard-input-width {
  width: 18rem;
}

.date-time-input {
  line-height: 1.5;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  height: 2.5rem;
}

.date-time-input-invalid {
  border-color: red;
}
