$wot-blitz-tournament-widget-individual-image-width: 725px;
$wot-blitz-tournament-widget-individual-image-height: 391px;

#wot-blitz-tournament-widget-individual-offcanvas.offcanvas-end,
#wot-blitz-tournament-widget-individual-offcanvas.offcanvas-start,
#wot-blitz-tournament-widget-individual-offcanvas.offcanvas-top,
#wot-blitz-tournament-widget-individual-offcanvas.offcanvas-bottom {
  font-family: 'VelaSans-Medium';
  width: $wot-blitz-tournament-widget-individual-image-width !important;
  height: $wot-blitz-tournament-widget-individual-image-height !important;
  border: none !important;
}

#wot-blitz-tournament-widget-individual-offcanvas {
  background-color: transparent !important;
}

#wot-blitz-tournament-widget-individual-offcanvas .offcanvas-body {
  overflow: hidden !important;
  width: $wot-blitz-tournament-widget-individual-image-width !important;
  height: $wot-blitz-tournament-widget-individual-image-height !important;
  padding: 0;
}

.wot-blitz-tournament-widget-individual-container {
  position: relative;
  width: $wot-blitz-tournament-widget-individual-image-width;
  height: $wot-blitz-tournament-widget-individual-image-height;
  color: white;
  background-image: url('./images/background.png');
}

.wot-blitz-tournament-widget-individual-tournament-name {
  position: absolute;
  margin: 0;
  top: 155px;
  left: 237px;
  width: 158px;
  height: 24px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
}

.wot-blitz-tournament-widget-individual-tournament-status {
  position: absolute;
  margin: 0;
  top: 155px;
  left: 407px;
  width: 157px;
  height: 24px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
}

.wot-blitz-tournament-widget-individual-tournament-status-pending {
  @extend .wot-blitz-tournament-widget-individual-tournament-status;
  color: white;
}

.wot-blitz-tournament-widget-individual-tournament-status-active {
  @extend .wot-blitz-tournament-widget-individual-tournament-status;
  color: green;
}

.wot-blitz-tournament-widget-individual-tournament-status-completed {
  @extend .wot-blitz-tournament-widget-individual-tournament-status;
  color: red;
}

.wot-blitz-tournament-widget-individual-tournament-place-label {
  position: absolute;
  margin: 0;
  top: 196px;
  left: 181px;
  width: 127px;
  height: 36px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
}

.wot-blitz-tournament-widget-individual-tournament-place {
  position: absolute;
  margin: 0;
  top: 180px;
  left: 309px;
  font-family: 'VelaSans-ExtraBold';
  font-size: 40px;
  width: 63px;
  height: 53px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.wot-blitz-tournament-widget-individual-tournament-scores-label {
  position: absolute;
  margin: 0;
  top: 196px;
  left: 375px;
  width: 80px;
  height: 36px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
}

.wot-blitz-tournament-widget-individual-tournament-scores {
  position: absolute;
  margin: 0;
  top: 180px;
  left: 456px;
  font-family: 'VelaSans-ExtraBold';
  font-size: 40px;
  width: 109px;
  height: 53px;
  text-align: center;
  white-space: nowrap;
  overflow: visible;
}

.wot-blitz-tournament-widget-individual-first-place-scores {
  position: absolute;
  margin: 0;
  top: 235px;
  left: 209px;
  width: 97px;
  height: 24px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
}

.wot-blitz-tournament-widget-individual-second-place-scores {
  position: absolute;
  margin: 0;
  top: 235px;
  left: 337px;
  width: 97px;
  height: 24px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
}

.wot-blitz-tournament-widget-individual-third-place-scores {
  position: absolute;
  margin: 0;
  top: 235px;
  left: 467px;
  width: 97px;
  height: 24px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
}
